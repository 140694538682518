// extracted by mini-css-extract-plugin
export var article = "article-module--article--w6lFN";
export var containerFlex = "article-module--containerFlex--zn63q";
export var content = "article-module--content--Om2de";
export var cover = "article-module--cover--l84nO";
export var description = "article-module--description--0P9r1";
export var header = "article-module--header--p-b7u";
export var info = "article-module--info--LdCLl";
export var publishDate = "article-module--publishDate--dVM-6";
export var tags = "article-module--tags--ilVHY";
export var type = "article-module--type--F807u";
export var updateDate = "article-module--updateDate--B9V8X";